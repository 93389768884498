/* eslint-disable no-console */
import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import type * as SpeechSDKType from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/microsoft.cognitiveservices.speech.sdk';
import { environment } from '../../../../../environments/environment';
import {
  AudioConfig,
  AutoDetectSourceLanguageConfig,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
} from './speech-sdk';

@Injectable()
export class AzureService
  implements fromSeriousSystem.VoiceToTextServiceInterface, OnDestroy
{
  private readonly azureApiKeyService =
    inject<fromSeriousSystem.VoiceToTextApiKeyServiceInterface>(
      fromSeriousSystem.VOICE_TO_TEXT_API_KEY_SERVICE
    );

  public isRecording = signal(false);
  public transcription = signal('');
  public isSpeaking = signal(false);
  public isReady = signal(false);

  private tempTranscription = '';
  private finalTranscription = '';
  private recognizer!: SpeechSDKType.SpeechRecognizer;

  // Audio detection
  private audioContext!: AudioContext;
  private mediaStream: MediaStream | null = null;
  private analyser!: AnalyserNode;
  private microphoneStream!: MediaStreamAudioSourceNode;
  private dataArray!: Uint8Array;
  private animationFrameId!: number;

  public async init() {
    await this.initializeRecognizer();

    this.audioContext = new AudioContext({
      latencyHint: 0.0001,
    });

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 2048;

    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
  }

  public async startRecording() {
    try {
      if (this.audioContext && this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }

      // Start audio detection
      await this.initializeAudioDetection();
      this.checkVolume();

      // Start continuous recognition
      await this.startContinuousRecognition();

      this.isReady.set(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      this.stopRecording();
      throw error;
    }
  }

  public stopRecording() {
    this.isSpeaking.set(false);
    this.isRecording.set(false);
    this.isReady.set(false);
    this.stopAzureRecognizer();
    this.stopAudioDetection();
  }

  // ------------------------------
  // Azure AI
  // ------------------------------
  private async initializeRecognizer() {
    const apiKey = await this.azureApiKeyService.getApiKey();
    const region = environment.azureSpeechToTextRegion ?? 'canadacentral';

    const speechConfig = SpeechConfig.fromAuthorizationToken(apiKey, region);
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

    const languageConfig = AutoDetectSourceLanguageConfig.fromLanguages([
      'en-US',
      'en-CA',
      'fr-CA',
    ]);
    speechConfig.speechRecognitionLanguage = 'en-US';

    this.recognizer = SpeechRecognizer.FromConfig(
      speechConfig,
      languageConfig,
      audioConfig
    );

    this.recognizer.recognizing = this.handleRecognizing.bind(this);
    this.recognizer.recognized = this.handleRecognized.bind(this);
    this.recognizer.canceled = this.handleCanceled.bind(this);
    this.recognizer.sessionStopped = this.handleSessionStopped.bind(this);
  }

  private readonly handleRecognizing = (
    recognizer: SpeechSDKType.Recognizer,
    recognizingEvent: SpeechSDKType.SpeechRecognitionEventArgs
  ) => {
    if (recognizingEvent.result.reason === ResultReason.RecognizingSpeech) {
      this.tempTranscription = recognizingEvent.result.text;
      this.transcription.set(this.finalTranscription + this.tempTranscription);
    }
  };

  private readonly handleRecognized = (
    recognizer: SpeechSDKType.Recognizer,
    recognizedEvent: SpeechSDKType.SpeechRecognitionEventArgs
  ) => {
    if (recognizedEvent.result.reason === ResultReason.RecognizedSpeech) {
      const newFinalTranscription = recognizedEvent.result.text;

      if (newFinalTranscription) {
        this.finalTranscription += newFinalTranscription + ' ';
        this.transcription.set(this.finalTranscription);
        this.tempTranscription = '';
      }
    } else if (recognizedEvent.result.reason === ResultReason.NoMatch) {
      console.log('No speech recognized.');
    }
  };

  private readonly handleCanceled = (
    recognizer: SpeechSDKType.Recognizer,
    canceledEvent: SpeechSDKType.SpeechRecognitionCanceledEventArgs
  ) => {
    console.error('Recognition canceled:', canceledEvent.reason);
    this.stopRecording();
  };

  private readonly handleSessionStopped = () => {
    console.log('Session stopped.');
    this.finalTranscription = '';
    this.stopRecording();
  };

  private startContinuousRecognition(): Promise<void> {
    console.log('Starting continuous recognition');
    return new Promise((resolve, reject) => {
      if (!this.recognizer) {
        console.error('Recognizer not initialized');
        reject(new Error('Recognizer not initialized'));
        return;
      }

      this.recognizer.startContinuousRecognitionAsync(
        () => {
          console.log('Recognition started.');
          this.isRecording.set(true);
          resolve();
        },
        (err) => {
          console.error('Error starting recognition:', err);
          this.stopRecording();
          reject(err);
        }
      );
    });
  }

  private stopAzureRecognizer() {
    if (this.recognizer) {
      void this.recognizer.stopContinuousRecognitionAsync(
        () => {
          console.log('Recognition stopped.');
        },
        (err) => {
          console.error('Error stopping recognition:', err);
        }
      );
    }
  }

  // ------------------------------
  // Audio detection
  //
  // @TODO: Move it to a shared service or a shared library to reuse it in other service like Deepgram
  //
  // ------------------------------
  private async initializeAudioDetection() {
    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    this.microphoneStream = this.audioContext.createMediaStreamSource(
      this.mediaStream
    );
    this.microphoneStream.connect(this.analyser);
  }

  private readonly checkVolume = () => {
    this.analyser.getByteTimeDomainData(this.dataArray);
    let sum = 0;
    for (const amplitude of this.dataArray) {
      sum += (amplitude - 128) ** 2;
    }
    const volume = Math.sqrt(sum / this.dataArray.length);

    const threshold = 2; // Play with this value to adjust the sensitivity
    if (volume > threshold) {
      this.isSpeaking.set(true);
    } else {
      this.isSpeaking.set(false);
    }

    this.animationFrameId = requestAnimationFrame(this.checkVolume);
  };

  private stopAudioDetection() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    if (this.microphoneStream) {
      this.microphoneStream.disconnect();
    }

    if (this.analyser) {
      this.analyser.disconnect();
    }

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.mediaStream = null;
    }
  }

  ngOnDestroy() {
    this.stopRecording();

    if (this.recognizer) {
      void this.recognizer.close();
    }

    if (this.audioContext) {
      void this.audioContext.close();
    }
  }
}
