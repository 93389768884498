import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import {
  LiveClient,
  LiveTranscriptionEvent,
  LiveTranscriptionEvents,
  createClient,
} from '@deepgram/sdk';
import * as fromSeriousSystem from '@serious-system';

@Injectable()
export class DeepgramService
  implements fromSeriousSystem.VoiceToTextServiceInterface, OnDestroy
{
  private readonly deepgramApiKeyService =
    inject<fromSeriousSystem.VoiceToTextApiKeyServiceInterface>(
      fromSeriousSystem.VOICE_TO_TEXT_API_KEY_SERVICE
    );

  public isRecording = signal(false);
  public transcription = signal('');
  public isSpeaking = signal(false);
  public isReady = signal(false);
  private mediaRecorder: MediaRecorder | null = null;
  private liveClient: LiveClient | null = null;
  private userMedia: MediaStream | null = null;

  public async init() {
    await this.createDeepgramClient();
  }

  public async startRecording() {
    try {
      this.transcription.set('');
      this.startStreaming();
      await this.openMicrophone();
      this.isRecording.set(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      this.stopRecording();
    }
  }

  public stopRecording() {
    this.closeMicrophone();
    if (this.liveClient) {
      this.liveClient.requestClose();
    }
    this.liveClient = null;
    this.isRecording.set(false);
  }

  private async createDeepgramClient() {
    const apiKey = await this.deepgramApiKeyService.getApiKey();
    const deepgram = createClient(apiKey);
    this.liveClient = deepgram.listen.live({
      language: 'en',
      smartFormat: true,
      model: 'nova-2',
      interimResults: true,
    });
  }

  private startStreaming() {
    if (!this.liveClient) return;

    this.liveClient.on(
      LiveTranscriptionEvents.Transcript,
      (data: LiveTranscriptionEvent) => {
        const transcript = data.channel?.alternatives[0]?.transcript || '';

        this.updateTranscription(transcript, data);
      }
    );

    this.liveClient.on(LiveTranscriptionEvents.Error, (error) => {
      console.error('Deepgram error:', error);
      this.stopRecording();
    });
  }

  private updateTranscription(
    transcription: string,
    data: LiveTranscriptionEvent
  ) {
    if (transcription) {
      if (data?.is_final) {
        if (this.transcription() === '') {
          this.transcription.set(transcription);
        } else {
          this.transcription.set(`${this.transcription()} ${transcription}`);
        }
      } else {
        this.transcription.set(`${this.transcription()} ${transcription}`);
      }
    }
  }

  private async openMicrophone() {
    try {
      this.userMedia = await navigator.mediaDevices.getUserMedia({
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
      });
      this.mediaRecorder = new MediaRecorder(this.userMedia);

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && this.liveClient) {
          this.liveClient.send(event.data);
        }
      };

      this.mediaRecorder.start(250);
    } catch (error) {
      console.error('Error opening microphone:', error);
      throw error;
    }
  }

  private closeMicrophone() {
    if (this.userMedia) {
      this.userMedia.getTracks().forEach((track) => track.stop());
      this.userMedia = null;
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
    }
  }

  ngOnDestroy() {
    this.stopRecording();
  }
}
